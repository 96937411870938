import React, { useState } from 'react'
import Content from '../templates/content'
import { makeStyles } from 'tss-react/mui';
import {
  CircularProgress,
  Typography,
  Box,
  Divider,
  Grid,
} from '@mui/material'
import CustomCookieConsent, {
  GPDR_COOKIES,
  cookieConsentGiven,
} from '../components/cookieConsent'

const useStyles = makeStyles()(theme => ({
  intro: {},
  spotifyWidget: {
    minHeight: '380px',
    width: '340px',
    maxWidth: '100%',
  },
  spotifyWidgetContainer: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  loadingContainer: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0, 0.5)',
  },
  divider: {
    paddingTop: '25px',
    paddingBottom: '25px',
  },
  quote: {
    fontStyle: 'italic',
  },
  youtube: {},
  noPointerEvents: {
    pointerEvents: 'none',
  },
  alignRight: {
    textAlign: 'right',
  },
  hidden: {
    display: 'none',
  },
}));

const Quote = ({ children }) => {
  const { classes } = useStyles()
  return (
    <Box>
      <p className={classes.quote}>{children}</p>
      <p className={classes.alignRight}>Andrea Bögel</p>
    </Box>
  )
}

const FloatResistantDivider = () => {
  const { classes } = useStyles()
  return (
    <div className={classes.divider}>
      <Divider light={true} />
    </div>
  )
}

const YouTubeEmbed = props => (
  <iframe
    title="Andrea Bögel auf YouTube"
    id="ytplayer"
    type="text/html"
    width="100%"
    height="360"
    src="https://www.youtube-nocookie.com/embed/videoseries?list=UU7O6KbkaE5lG8hkHkGhZJ7g&rel=0"
    frameBorder="0"
    allowFullScreen
    {...props}
  ></iframe>
)

const SpotifyEmbed = props => (
  <iframe
    title={'Andrea Bögel auf Spotify'}
    src="https://open.spotify.com/embed/artist/5xl6VetSF7NkGaYMOEZQaw"
    frameBorder="0"
    allow="encrypted-media"
    {...props}
  ></iframe>
)

const MusicPage = () => {
  const { classes, cx } = useStyles()
  const [cookieConsent, setCookieConsent] = useState(
    cookieConsentGiven(GPDR_COOKIES.Music)
  )
  const [iframeLoading, setIframeLoading] = useState({
    youtube: true,
    spotify: true,
  })

  const onCookieConsentChange = () => {
    setCookieConsent(cookieConsentGiven(GPDR_COOKIES.Music))
  }

  const renderSpotifyIframe = () => (
    <>
      {iframeLoading['spotify'] && (
        <Box className={cx(classes.spotifyWidget, classes.loadingContainer)}>
          <CircularProgress color={'secondary'} size={60} />
        </Box>
      )}
      <SpotifyEmbed
        className={cx(
          classes.spotifyWidget,
          iframeLoading['spotify'] && classes.hidden
        )}
        onLoad={() => setIframeLoading(prev => ({ ...prev, spotify: false }))}
      />
    </>
  )

  const renderCookieHint = () => (
    <Box
      p={4}
      className={cx(classes.spotifyWidget, classes.loadingContainer)}
    >
      <Typography variant="body1">
        Sie müssen die Cookies akzeptieren, um Titel auf Spotify und YouTube zu
        hören.
      </Typography>
    </Box>
  )

  return (
    <Content
      title="Musik"
      description={`Das Singen, die Musik sind die Flügel meiner Seele, der absolute
    Ausdruck meines Herzens. Unabhängig von jeder Stilistik möchte ich
    die Geschichte, die ich mit der Sprache der Musik zum Leben
    erwecke, wahrhaftig erzählen. Jedes Lied, ein kleiner Film, dessen
    Bilder und Gefühle man nachspüren kann.`}
    >
      <Box className={classes.intro}>
        <Grid container>
          <Grid item md={6}>
            <Quote>
              „Das Singen, die Musik sind die Flügel meiner Seele, der absolute
              Ausdruck meines Herzens. Unabhängig von jeder Stilistik möchte ich
              die Geschichte, die ich mit der Sprache der Musik zum Leben
              erwecke, wahrhaftig erzählen. Jedes Lied, ein kleiner Film, dessen
              Bilder und Gefühle man nachspüren kann.“
            </Quote>
          </Grid>
          <Grid item md={6} className={classes.spotifyWidgetContainer}>
            {cookieConsent ? renderSpotifyIframe() : renderCookieHint()}
          </Grid>
        </Grid>
      </Box>
      <FloatResistantDivider />
      <Quote>
        „Ich selbst bin mit ganz unterschiedlichen musikalischen Farben und
        Stilistiken aufgewachsen. Darum zählt für mich immer nur die Qualität
        und die Glaubwürdigkeit, mit der ein Musikstück musiziert wird, und
        nicht die Tatsache, ob es ein klassisches Werk oder ein Hip-Hop-Titel
        ist. Ich finde es schade, dass es immer noch nicht gelungen ist, die
        sogenannte U- und E-Musik gleichwertig zu beurteilen. Ich hätte mir für
        Deutschland immer eine Art „Juillard -School“ gewünscht, in der
        Komponisten, Dirigenten, Sänger, Tänzer, Regisseure u.v.a. miteinander
        arbeiten und sich gegenseitig inspirieren, unabhängig von jeder
        Kategorie. <br />
        <br />
        Mein persönlicher Traum ist immer noch ein „House of Arts“, in dem alle
        künstlerischen Ausdrucksformen zusammen kommen, Maler, Bildhauer,
        Autoren, und alle anderen Ausdruckformen der darstellenden Künste! Am
        Ende bleibt doch für jeden Künstler nur der momentane und sehr flüchtige
        Augenblick, in dem manchmal alles zu gelingen scheint und jeder, der
        zuhört oder diesen Moment teilt, sich in wunderbaren Geschichten
        wiederfindet, ob in einem Chanson, in einer Arie, in einer Big Band-
        Nummer oder in einem gesprochenen Text.“
      </Quote>
      <FloatResistantDivider />
      <Box
        className={classes.youtube}
        onClick={() => {
          if (!cookieConsent) {
            alert(
              'Sie müssen die Cookies akzeptieren, um Titel auf YouTube zu hören.'
            )
          }
        }}
      >
        <YouTubeEmbed
          className={cx(!cookieConsent && classes.noPointerEvents)}
          onLoad={() => setIframeLoading(prev => ({ ...prev, youtube: false }))}
        />
      </Box>
      <CustomCookieConsent onChange={onCookieConsentChange} />
    </Content>
  );
}

export default MusicPage
