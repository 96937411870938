import React from 'react'
import Layout from './layout'
import { Box, Typography, Divider } from '@mui/material'
import { makeStyles } from 'tss-react/mui';
import { BoegelThemes } from './themeProvider'
import SEO from '../components/seo'
import { isSafariLike } from '../utils/runtimeEnvDetectionUtils'
import Grizabella1 from '../images/grizabella_blur2.jpg'
import Grizabella2 from '../images/grizabella-performance_blur.jpg'
import CatsMask from '../images/cats-mask_blur1.jpg'

const bgImgRotation = [
  {
    image: `url(${Grizabella1})`,
    position: 'center top',
  },
  {
    image: `url(${Grizabella2})`,
    position: 'center',
  },
  {
    image: `url(${CatsMask})`,
    position: 'center',
  },
]

const useStyles = makeStyles()((theme, props) => {
  const bgImg = bgImgRotation[Math.floor(Math.random() * bgImgRotation.length)]
  return {
    root: {
      backgroundImage: bgImg.image,
      backgroundColor: '#000',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: props.isSafariLike ? 'scroll' : 'fixed',
      backgroundPosition: bgImg.position,
      backgroundSize: 'cover',
      overflow: 'hidden',
      minHeight: 'inherit',
    },
    darkenBg: {
      background: `rgba(17, 17, 17, 0.5)`,
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      minHeight: 'inherit',
    },
    contentBox: {
      maxWidth: '750px',
      padding: `0 ${theme.spacing(2)}px`,
    },
    dividerToTextSpacing: {
      marginBottom: '40px',
    },
  }
});

const Content = ({ children, caption, title, description }) => {
  const { classes } = useStyles({ isSafariLike })

  return (
    <Layout theme={BoegelThemes.SCENIC}>
      <SEO title={title} description={description} />
      <div className={classes.root}>
        <div className={classes.darkenBg}>
          <Box my={10} mx={'auto'} className={classes.contentBox}>
            <Typography variant="h1">{title}</Typography>
            <Typography variant="caption">{caption}</Typography>
            <Divider className={classes.dividerToTextSpacing} />
            <Typography variant="body1" component="div">
              {children}
            </Typography>
          </Box>
        </div>
      </div>
    </Layout>
  )
}

export default Content
