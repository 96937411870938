import React from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { Button } from '@mui/material'
import { LinkBase } from './link'
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      zIndex: '9999 !important',
      ...theme.mixins.toolbar,
      alignItems: 'center !important',
      width: '100%',
      position: 'fixed',
      left: '0',
      bottom: '0',
      justifyContent: 'space-between',
      display: 'flex',
      flexWrap: 'wrap',
      background: theme.palette.background.default,
    },
    text: {
      flex: '9 0 300px',
      margin: '15px',
    },
    button: {},
    buttonWrapper: {
      '& button:first-of-type': {
        marginRight: '15px',
      },
      flex: '0 0 auto',
      margin: '15px',
    },
  }
});

export const GPDR_COOKIES = {
  Music: 'gpdr-music',
}

export const cookieConsentGiven = cookie => {
  if (!cookie) {
    return false
  }
  const cookieValue = Cookies.get(cookie)
  if (!cookieValue || `${cookieValue}`.toLowerCase() !== 'true') {
    return false
  }
  return true
}

const CustomCookieConsent = ({ onChange }) => {
  const { classes } = useStyles()
  const acceptLabel = 'Akzeptieren'
  const declineLabel = 'Ablehnen'
  return (
    <CookieConsent
      disableStyles={true}
      containerClasses={classes.root}
      contentClasses={classes.text}
      buttonClasses={classes.button}
      buttonWrapperClasses={classes.buttonWrapper}
      ButtonComponent={props => {
        return (
          <Button
            variant="contained"
            color={props.children === declineLabel ? 'secondary' : 'primary'}
            type="button"
            {...props}
          />
        )
      }}
      location="bottom"
      buttonText={acceptLabel}
      onAccept={onChange}
      onDecline={onChange}
      enableDeclineButton={true}
      declineButtonText={declineLabel}
      sameSite={'strict'}
      cookieName={GPDR_COOKIES.Music}
      cookieValue={'true'}
      declineCookieValue={'false'}
    >
      <>
        Diese Seite nutzt Cookies der Drittanbieter Spotify und YouTube, um
        Musik von der der jeweiligen Plattform abzuspielen.{' '}
        <LinkBase to="/imprint#cookies">Mehr Infos</LinkBase>
      </>
    </CookieConsent>
  )
}

export default CustomCookieConsent
